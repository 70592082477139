<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-enter">
        <div class="col-md-6 text-center">
          <nav>
            <ul>
              <li>
                <div class="d-md-flex align-items-center text-center">
                  <img src="assets/img/Phila-3-1024x1024.png" alt="" srcset="" class="img-fluid mr-2" style="width:5%">
                  <a href="https://ce.church" class="mt-md-1">
                    Phila c.e
                  </a>
                </div>
              </li>
              <!-- <li>
                <a href="">
                  Infos
                </a>
              </li> -->
            </ul>
          </nav>
        </div>
        <div class="col-md-6 text-center">
          <div class="copyright">
            &copy; {{ new Date().getFullYear() }}, produit par <a href="https://ce.church"
              class="category text-dark" style="color: #66615b;">Phila c.e</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'FooterPage',

  data() {
    return {
      user: null,
      devises: [],
      form: {
        names: null,
        profiler_etat_civil: "Non défini",
        profiler_phone: null,
        profiler_email: null,
        profiler_sex: null,
        devise_id: null,
        projet_souscripteur_price_estimation: null,
        city_name: null,
        type_souscription_id: null,
        self_souscription: null,
        step_one: true,
        step_two: false
      },
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming: null
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  methods: {

    async all_devises() {
      await axios.get(this.$store.state.app_url_dev + "devises").then(({ data }) => {
        if (data.status == 1) {
          this.devises = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
        console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },
  }
}
</script>
