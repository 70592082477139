<template>
  <div>
    <navbar-globale></navbar-globale>


    <div class="main-panel">
        <navbar-globale-mobile></navbar-globale-mobile>

        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div  class="col-lg-12">
                        <div class="card">
                            <div class="content">
                                <div class="row align-items-center">
                                    <div class="col-xs-9">
                                        <div class="text-left">
                                            <span class="h5 text-dark mt-2">TAUX</span>
                                        </div>
                                    </div>
                                    <div v-if="this.$store.state.tauxes != null && this.$store.state.tauxes.length > 0" class="col-xs-3 text-right">
                                      <p class="category">
                                      <strong v-if="this.gb_devise_one_to_go_convert_two('usd', 'cdf').length > 0 && this.$store.state.tauxes != null" class="text-danger">1USD {{ this.gb_separateur_milliers_chiffre_grp_3(this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price) }} CDF</strong> <br/>
                                      <strong v-if="this.gb_devise_one_to_go_convert_two('euro', 'usd').length > 0 && this.$store.state.tauxes != null" class="text-danger">EURO {{ this.gb_separateur_milliers_chiffre_grp_3(this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price) }} USD</strong> <br/>
                                      </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-sm-6" style="height: 40%;">
                        <div class="card">
                          <router-link to="/payment">
                            <div class="content">
                                <div class="row">
                                    <div class="col-xs-5">
                                        <div class="icon-big icon-success text-center">
                                            <i class="ti-wallet"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <div class="numbers">
                                            <p>Revenus</p>
                                            ${{ this.projetActif !=null && this.projetActifPayments !=null && this.projetActifPayments.length > 0 && this.$store.state.tauxes != null ? this.gb_separateur_milliers_chiffre_grp_3(this.sumPayments()) : 0}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </router-link>
                        </div>
                    </div>
                    <div  class="col-lg-3 col-sm-6">
                        <div class="card">
                            <div class="content">
                                <div class="row">
                                    <div class="col-xs-5">
                                        <div class="icon-big icon-warning text-center">
                                            <i class="ti-user"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <div class="numbers">
                                            <p>Capacité</p>
                                            {{this.profilersUsers.length}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.projetActif !=null && this.projetActif.souscripteurs !=null" class="col-lg-3 col-sm-6">
                        <div class="card">
                            <div class="content">
                                <div class="row">
                                    <div class="col-xs-5">
                                        <div class="icon-big icon-danger text-center">
                                            <i class="ti-widget-alt"></i>
                                            <!-- <i class="ti-pulse"></i> -->
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <div class="numbers">
                                            <p>Souscription</p>
                                            {{this.projetActif.souscripteurs.length}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-sm-6">
                        <div class="card">
                            <div class="content">
                                <div class="row">
                                    <div class="col-xs-5">
                                        <div class="icon-big icon-info text-center">
                                            <i class="ti-twitter-alt"></i>
                                        </div>
                                    </div>
                                    <div class="col-xs-7">
                                        <div class="numbers">
                                            <p>Followers</p>
                                            +45
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="row d-sm-none">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="header">
                                <h4 class="title">Users Behavior</h4>
                                <p class="category">24 Hours performance</p>
                            </div>
                            <div class="content">
                                <div id="chartHours" class="ct-chart"></div>
                                <div class="footer">
                                    <div class="chart-legend">
                                        <i class="fa fa-circle text-info"></i> Open
                                        <i class="fa fa-circle text-danger"></i> Click
                                        <i class="fa fa-circle text-warning"></i> Click Second Time
                                    </div>
                                    <hr>
                                    <div class="stats">
                                        <i class="ti-reload"></i> Updated 3 minutes ago
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row" style="display:none;">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="header">
                                <h4 class="title">Paiement Evolution</h4>
                                <!-- <p class="category">Last Campaign Performance</p> -->
                            </div>
                            <div class="content">
                                <div id="chartPreferences" class="ct-chart ct-perfect-fourth"></div>

                                <div class="footer">
                                    <div class="chart-legend">
                                        <i class="fa fa-circle text-info"></i>Souscription Payant
                                        <!-- <i class="fa fa-circle text-danger"></i> Bounce -->
                                        <i class="fa fa-circle text-warning"></i> Souscription en Attente
                                    </div>
                                    <hr>
                                    <!-- <div class="stats">
                                        <i class="ti-timer"></i> Campaign sent 2 days ago
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card ">
                            <div class="header">
                                <h4 class="title">Evolution dans le temps</h4>
                                <!-- <p class="category">All products including Taxes</p> -->
                            </div>
                            <div class="content">
                                <div id="chartActivity" class="ct-chart"></div>

                                <div class="footer">
                                    <div class="chart-legend">
                                        <i class="fa fa-circle text-info"></i> Tesla Model S
                                        <i class="fa fa-circle text-warning"></i> BMW 5 Series
                                    </div>
                                    <hr>
                                    <!-- <div class="stats">
                                        <i class="ti-check"></i> Data information certified
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer-globale></footer-globale>

    </div>
  </div>
</template>

<script>
import NavbarGlobale from './layouts/NavBar.vue';
import NavbarGlobaleMobile from './layouts/NavBarMobile.vue';
import FooterGlobale from './layouts/FooterGlobale.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'Dashboard',
  components:{NavbarGlobale,FooterGlobale,NavbarGlobaleMobile},
  data() {
    return {
      user: null,
      devises: [],
      profilersUsers: [],
      projetActif:null,
      projetActifPayments:[],
      form:{
        names:null,
        profiler_etat_civil:"Non défini",
        profiler_phone:null,
        profiler_email:null,
        profiler_sex:null,
        devise_id:null,
        projet_souscripteur_price_estimation:null,
        city_name:null,
        type_souscription_id:null,
        self_souscription:null,
        step_one:true,
        step_two:false
      },
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming:null
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gd_refresh_taux();
    this.projet_actif();
    this.profilers_userS();
    this.all_devises();
    this.gb_deleteCss();

    // this.gb_addJs();

    this.$store.state.user_auth = JSON.parse(localStorage.getItem("AUTHENTIFICATE_USER"));

    setInterval(() => {
      this.projet_actif();
      this.profilers_userS();
      this.all_devises();
    }, 300000);
  },
  methods: {

    async projet_actif() {
      await axios.get(this.$store.state.app_url_dev + "projets_actif").then(({ data }) => {
       if (data.status == 1) {
          this.projetActif = data.data;
          this.projetActifPayments = data.paymentsFilter;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
      });
    },

    async profilers_userS() {
      await axios.get(this.$store.state.app_url_dev + "users_profilers").then(({ data }) => {
       if (data.status == 1) {
          this.profilersUsers = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
      });
      // .catch(function (error) {
      //  console.log(error);
      //   this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
      //     timeout: 2000
      //   });
      // });
    },

    async  all_devises() {
      await axios.get(this.$store.state.app_url_dev + "devises").then(({ data }) => {
       if (data.status == 1) {
          this.devises = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
      });
    },

    sumPayments() {
     if(this.projetActif !=null && this.projetActifPayments.length > 0 && this.$store.state.tauxes != null && this.$store.state.tauxes.length > 0) {
        var total = 0;
        this.projetActifPayments.forEach(element => {
          var totalElement = 0;

          if (element?.devise?.devise_title.toString().toUpperCase() =="USD") {
            totalElement = element?.payment;
            } else if(element?.devise?.devise_title.toString().toUpperCase() == "CDF") {
              if(element?.taux !=null){
                totalElement = parseFloat(parseFloat(element?.payment) / parseFloat(element?.taux?.taux_price)).toFixed(2);
              }else{
                totalElement =  parseFloat(parseFloat(element?.payment) /  parseFloat(this.gb_devise_one_to_go_convert_two('usd', 'cdf')[0].taux_price)).toFixed(2);
              }

            } else if (element?.devise?.devise_title.toString().toUpperCase() == "EURO") {

              if(element?.taux !=null){
                totalElement = parseFloat(parseFloat(element?.payment) * parseFloat(element?.taux?.taux_price)).toFixed(2);
              }else{
                totalElement =  parseFloat(parseFloat(element?.payment) *  parseFloat(this.gb_devise_one_to_go_convert_two('euro', 'usd')[0].taux_price)).toFixed(2);
              }
            }
            
            total += parseFloat(totalElement);
        });

        return total.toFixed(2);
      }
    },

    async storeSouscripteur() {
      
      if(this.form.self_souscription !=null ){
        this.form.type_souscription_id = 2;
      }else{
        this.form.type_souscription_id = 1;
      }

      if(this.form.names.trim() !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone.trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      //  var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());

        await axios.post(this.$store.state.app_url_dev + "souscripteur",this.form).then(({ data }) => {
        if (data.status == 1) {
          var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Votre souscription vient d'être fait pour le projet 7000 Partenaires. (Phila-c.e)";


          let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());
          // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=test&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());

          axios.get(urlgetsms).then(() => { });
          // this.$store.state.user_auth = data;
          // this.$store.state.user_store = data;
          // setTimeout(() => {
          //     this.refreshForm();
          //     this.step = 1;
          //     this.lottieloading = false;
          //   }, 15001);

          setTimeout(() => {
            this.form.step_one = false;
            this.form.step_two = true;
            this.lottieloading = false;
            this.reseRefreshForm();

            this.settiming = setInterval(() => {
              this.newsSouscripteur();
            }, 6000);
            
          }, 4500);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });
          }
        }).catch(function (error) {
        console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }else{
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    to_submit_form() {
      // if (this.form.names.toString().trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      if(this.form.names !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex !=null && this.form.profiler_sex.toString().trim() !=null) {
        this.lottieloading = true;
        // if (this.Online()) {
          this.storeSouscripteur();
          // this.RegisterDemandUser();
           // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // } 
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    reseRefreshForm() {
      this.form.names = null;
      this.form.profiler_etat_civil = "Non défini";
      this.form.profiler_phone = null;
      this.form.profiler_email = null;
      this.form.profiler_sex = null;
      this.form.devise_id = null;
      this.form.projet_souscripteur_price_estimation = null;
      this.form.city_name = null;
      this.form.type_souscription_id = null;
      this.form.self_souscription = null;
    },

    newsSouscripteur() {
      clearInterval(this.settiming);
      this.reseRefreshForm();
      this.form.step_one = true;
      this.form.step_two = false;
      this.lottieloading = false;
      this.settiming = null;
    },

    async  all_modules() {
      await axios.get(this.$store.state.app_url_dev + "modules").then(({ data }) => {
       if (data.status == 1) {
          this.modules = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_levels() {
      await axios.get(this.$store.state.app_url_dev + "levels").then(({ data }) => {
       if (data.status == 1) {
          this.levels = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_cities() {
      await axios.get(this.$store.state.app_url_dev + "cities").then(({ data }) => {
       if (data.status == 1) {
          this.cities = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    access_step_two() {
      if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
        this.lottieloading = true;
        // if (this.Online()) {
          setTimeout(() => {
            this.step = 2;
            this.lottieloading = false
          }, 3000);
        // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // } 
      } else {
        this.checkForm = true;
        if (this.form.identity_middlename == null || this.form.identity_middlename.trim() == "") {
            this.toast.error("Veuillez compléter le nom", {
              timeout: 5000
            });
        } else {
          this.toast.error("Veuillez compléter les champs manquants", {
            timeout: 5000
          });
        }
      }
    },


    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.identity_sex = typesexe;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return  value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
