import Index from './components/Index.vue'
import Dashboard from './components/Dashboard.vue'
import Payment from './components/Payment.vue'
import Rapport from './components/Rapport.vue'
import Settings from './components/Settings.vue'
// import Souscripteur from './components/Souscripteur.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
    name: "LoadPage",
    component: Index,
    path: '/'
}, {
    name: "Dashboard",
    component: Dashboard,
    path: '/dashboard'
}, {
    name: "Payment",
    component: Payment,
    path: '/payment'
}, {
    name: "Rapport",
    component: Rapport,
    path: '/rapport'
}, {
    name: "Settings",
    component: Settings,
    path: '/settings'
}];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
// ViewOtherUser