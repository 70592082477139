<template>
  <div>
    <navbar-globale></navbar-globale>


    <div class="main-panel">
        <navbar-globale-mobile></navbar-globale-mobile>

        <div class="content">
          <div class="container-fluid">
            <div class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-7">
                <h2 class="title">Taux</h2>
                <hr>
              </div>
              <div class="col-md-3">
                  <button class="btn btn-default btn-block" data-toggle="modal" data-target=".modal-notif">Mettre à jour</button>
              </div>
            </div>
            <div v-if="this.dt_taux.length > 0" class="row justify-content-center align-items-center  my-3" style="display: flex;">
              <div class="col-md-9" style="height-max:450px; overflow-y: auto;">
                <table class="table table-striped table-hover">
                    <thead>
                        <th>ID</th>
                      <th>Devise D.</th>
                      <th>Montant</th>
                      <th>Devise Conv.</th>
                      <th>Statut</th>
                      <th>Date création</th>
                    </thead>
                    <tbody>
                        <tr v-for="(tx, index) in dt_taux" :key="index" class="col">
                          <td class="font-weight-bold">{{index + 1}}</td>
                          <td>{{ tx?.devise?.devise_title }}</td>
                          <td> 
                            <strong class="text-dark"  style="color:#1C1B1B;">{{ tx.taux_price }}</strong>
                          </td>
                          <td>{{ tx?.devise_convert?.devise_title }}</td>
                          <td>
                            <i  v-if="tx.status_id == 1" class="ti ti-check text-success"></i>
                            <i v-else class="ti ti-close text-danger"></i>
                          </td>
                          <td>{{ tx?.created_at.toString().substring(0,10)}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>

            <!-- Data Excel -->
            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN' && this.dt_DataExcel.length > 0" class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-10">
                <h2 class="title">Données Excel</h2>
                <span>{{ this.dt_DataExcel.length  }} données disponible</span>
                <div>
                  <strong>{{ this.dt_DataExcel.filter(function(elemnt){
                    return elemnt.price != 0;
                  }).length  }} souscripteurs avec paiement</strong>  & <strong>{{ this.dt_DataExcel.filter(function (elemnt) {
                    return elemnt.price == 0;
                  }).length }} souscripteurs sans paiement</strong>

                </div>
                <hr>
              </div>
            </div>

            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN' && this.dt_DataExcel.length > 0 && !this.lottieloading" class="row justify-content-center align-items-center  my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-9">
                <div class="content table-responsive table-upgrade" style="height:450px; overflow-y: auto;">
                      <table class="table table-striped table-hover">
                          <thead>
                              <th>ID</th>
                            <th>Noms</th>
                            <th>Montant Tot.</th>
                            <th>Phone</th>
                            <th>Email</th>
                          </thead>
                          <tbody>
                              <tr v-for="(souscript, index) in dt_DataExcel" :key="index" class="col">
                                <td class="font-weight-bold">{{ index + 1 }}</td>
                                <td>{{ souscript?.names }}</td>
                                <td> 
                                  <strong class="text-dark"  style="color:#1C1B1B;">{{ souscript.price == 0 ? "-" : souscript?.price + " USD" }} </strong>
                                </td>
                                <td>{{ souscript?.profiler_phone != null ? souscript?.profiler_phone : "-" }}
                                </td>
                                <td>{{ souscript?.devise_convert?.profiler_email != null ? souscript?.devise_convert?.profiler_email : "-" }}</td>
                              </tr>
                          </tbody>
                      </table>
                </div>
              </div>
            </div>

            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN' && this.dt_DataExcel.length > 0  && !this.lottieloading" class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-8 text-right">
                  <button class="btn btn-default  float-right" @click.prevent="loadExcelSouscripteurs">Mettre la base de données à jour</button>
              </div>
            </div>

            <div v-if="this.dt_DataExcel.length > 0 && this.lottieloading" class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-4 text-center">
                  <lottie-player  class="text-center"
                                                        :src="this.$store.state.app_url_img_localhost + '/assets/lotties/loading/load7.json'" background="transparent" speed="1" style="width: 100%; height: 90px;" loop
                                                        autoplay></lottie-player>
                  <strong class="small">{{  this.$store.state.user_dataExcel }} souscripteurs sauvegardés</strong>
              </div>

                <div class="col-md-8">
                <div class="content table-responsive table-upgrade" style="height:450px; overflow-y: auto;">
                      <table class="table table-striped table-hover">
                          <thead>
                              <th>ID</th>
                            <th>Message</th>
                          </thead>
                          <tbody>
                              <tr v-for="(mess, index) in this.$store.state.user_dataExcelMessage" :key="index" class="col">
                                <td class="font-weight-bold">{{ index + 1 }}</td>
                                <td>{{ mess?.msg }}</td>
                              </tr>
                          </tbody>
                      </table>
                </div>
              </div>
            </div>

              <!-- // Sms Notifications user -->
            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN' && this.dt_notifications.length > 0  && !this.lottieloading" class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-6">
                <h2 class="title">Notification Message</h2>
              </div>
              <div class="col-md-3">
                  <button class="btn btn-default btn-block" data-toggle="modal" data-target=".modal-notificationPartenaire" @click="load_data_notification">Mettre à jour</button>
              </div>
            </div>

            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN' && this.dt_notifications.length > 0" class="row justify-content-center align-items-center  my-3" style="display: flex;">
              <div class="col-md-9" style="height-max:450px; overflow-y: auto;">
                <table class="table table-striped table-hover">
                    <thead>
                      <th></th>
                    </thead>
                    <tbody>
                        <tr v-for="(notificate, index) in dt_notifications" :key="index" class="col">
                          <td>
                            <div class="justify-content-center">
                              <div class="col-11 my-3">
                                <small class="text-uppercase text-xs">Notification de souscription</small>
                                <br/>
                                <span class="text-dark" style="color:#1C1B1B;">
                                  {{ (notificate?.sms_partenaire_souscription !=null && notificate?.sms_partenaire_souscription.trim() !="" ? notificate?.sms_partenaire_souscription : '' )}}
                                </span>
                                <hr>
                              </div>

                              <div class="col-12 my-3">
                                <small class="text-uppercase text-xs">Notification de paiement</small>
                                <br/>
                                <span class="text-dark" style="color:#1C1B1B;">
                                  {{ (notificate?.sms_partenaire_add_price !=null && notificate?.sms_partenaire_add_price.trim() !="" ? notificate?.sms_partenaire_add_price : '' )}}
                                </span>
                                <hr>
                              </div>
                              <div class="col-12 my-3">
                                <small class="text-uppercase text-xs">Notification de rappel</small>
                                <br/>
                                <span class="text-dark" style="color:#1C1B1B;">
                                  {{ (notificate?.sms_partenaire_rappel !=null && notificate?.sms_partenaire_rappel.trim() !="" ? notificate?.sms_partenaire_rappel : '' )}}
                                </span>
                                <hr>
                              </div>
                            </div>
                            <p class="text-xs small text-right">Modifié {{this.bg_format_date(notificate?.updated_at.toString().substring(0,10))}}</p>
                          </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>

            <!-- // Administration user -->
            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN'" class="row justify-content-center align-items-center my-3" style="margin-bottom:3%;display: flex;">
              <div class="col-md-6">
                <h2 class="title">Administation Utilisateur</h2>
                <span>{{ this.usersAdmin.length }} {{ this.usersAdmin.length > 1 ? 'utilisateurs ':' utilisateur ' }} au total</span> & <span>{{ this.usersAdmin.length }} {{ this.usersAdmin.length > 1 ? 'utilisateurs actifs':' utilisateur actif' }}</span>
                <hr>
              </div>
              <div class="col-md-3">
                  <button class="btn btn-default btn-block" data-toggle="modal" data-target=".modal-majAdmin">Mettre à jour</button>
              </div>
            </div>

            <div v-if="this.$store.state.user_auth?.role?.rule_designation =='ADMIN' && this.usersAdmin.length > 0" class="row justify-content-center align-items-center  my-3" style="display: flex;">
              <div class="col-md-9" style="height-max:450px; overflow-y: auto;">
                <table class="table table-striped table-hover">
                    <thead>
                      <th>ID</th>
                      <th>Noms</th>
                      <th>Téléphone</th>
                      <th>Email</th>
                      <th>Statut</th>
                      <th>Date création</th>
                    </thead>
                    <tbody>
                        <tr v-for="(admin, index) in usersAdmin" :key="index" class="col">
                          <td class="font-weight-bold">{{index + 1}}</td>
                          <td>
                            <strong class="text-dark" style="color:#1C1B1B;">
                              {{ admin?.profiler?.profiler_prenom }} {{ (admin?.profiler?.profiler_nom !=null && admin?.profiler?.profiler_nom !="Non défini" ? admin?.profiler?.profiler_nom : '' )}} {{ (admin?.profiler?.profiler_postnom !=null && admin?.profiler?.profiler_postnom !="Non défini" ? admin?.profiler?.profiler_postnom : '' )}}
                            </strong>
                            <br/>
                            <small class="text-uppercase text-xs">{{ admin?.role?.rule_designation }}</small>
                          </td>
                          <td> 
                            <span >{{  admin?.profiler?.profiler_phone }}</span>
                          </td>
                          <td>{{ admin?.profiler?.profiler_email}}</td>
                          <td>
                            <i v-if="admin.status_id == 1" class="ti ti-check text-success"></i>
                            <i v-else class="ti ti-close text-danger"></i>
                          </td>
                          <td>{{ admin?.created_at.toString().substring(0,10)}}</td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <footer-globale></footer-globale>
    </div>

    <!-- // Admin -->
    <div class="modal fade modal-majAdmin modal-slide" tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true " >
      <div class="modal-dialog modal-md" role="document ">
          <div class="modal-content ">
              <div class="modal-header ">
                  <h5 class="modal-title  text-uppercase" id="defaultModalLabel">Nouveau Administrateur</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeVerif()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body ">
                <div class="row justify-content-center align-items-center" style="display: flex;">
                  <div class="col-md-10 mb-4" style="margin-bottom:3%;">
                    <div class="row" style="margin-bottom: 15px;">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Nom complet<span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                            <input type="text" class="form-control border-input" placeholder="noms administrateur"  v-model="this.form_admin.names" >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Sexe<span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <select v-model="this.form_admin.profiler_sex" class="form-control border-input">
                                <option  value=""></option>
                                <option  value="M">Homme</option>
                                <option  value="F">Femme</option>
                              </select>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Téléphone<span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                            <input type="text" class="form-control border-input" placeholder="téléphone"  v-model="this.form_admin.profiler_phone" >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Adresse email</span>
                          </div>
                          <div class="col-md-12">
                            <input type="text" class="form-control border-input" placeholder="email"  v-model="this.form_admin.profiler_email" >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Profil<span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <select v-model="this.form_admin.rule_id" class="form-control border-input">
                                <option  v-for="(role, index) in load_rules_not_admin" :key="index" :value="role.id">{{role.rule_designation}}</option>
                              </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button class="btn btn-default"  @click.prevent="close()"  type="button" data-dismiss="modal" aria-label="Close" style="margin-right: 4%;">Annuler </button>
                      <button class="btn btn-default" @click.prevent="submitAddAdmin()"    type="button" data-dismiss="modal" aria-label="Close">Ajouter Admin</button>
                    </div>
                  </div>
              </div>
              <!-- <div class="modal-footer ">
                  <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Ajouter</button>
              </div> -->
          </div>
      </div>
    </div>
    <!--  Notification -->
    <div class="modal fade modal-notificationPartenaire modal-slide" tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true " >
      <div class="modal-dialog modal-md" role="document ">
          <div class="modal-content ">
              <div class="modal-header ">
                  <h5 class="modal-title  text-uppercase" id="defaultModalLabel">MISE A JOUR DES NOTIFICATIONS</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeVerif()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body ">
                <div class="row justify-content-center align-items-center" style="display: flex;">
                  <div class="col-md-10 mb-4" style="margin-bottom:3%;">
                    <div class="row" style="margin-bottom: 15px;">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Notification de souscription</span>
                          </div>
                          <div class="col-md-12">
                            <textarea  cols="30" rows="3" class="form-control border-input" v-model="this.form_notification.sms_partenaire_souscription"></textarea>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col-md-12">
                            <span>Notification de paiement</span>
                          </div>
                          <div class="col-md-12">
                            <textarea  cols="30" rows="3" class="form-control border-input" v-model="this.form_notification.sms_partenaire_add_price"></textarea>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col-md-12">
                            <span>Notification de rappel</span>
                          </div>
                          <div class="col-md-12">
                            <textarea  cols="30" rows="3" class="form-control border-input" v-model="this.form_notification.sms_partenaire_rappel"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button class="btn btn-default"  @click.prevent="close()"  type="button" data-dismiss="modal" aria-label="Close" style="margin-right: 4%;">Annuler </button>
                      <button class="btn btn-default" @click.prevent="submitUpdateNotificatinPartenaire()"    type="button" data-dismiss="modal" aria-label="Close">Mettre à jour</button>
                    </div>
                  </div>
              </div>
              <!-- <div class="modal-footer ">
                  <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Ajouter</button>
              </div> -->
          </div>
      </div>
    </div>
    
    <div class="modal fade modal-notif modal-slide" tabindex="-1 " role="dialog " aria-labelledby="defaultModalLabel " aria-hidden="true" >
      <div class="modal-dialog modal-md" role="document ">
          <div class="modal-content ">
              <div class="modal-header ">
                  <h5 class="modal-title  text-uppercase" id="defaultModalLabel">Nouveau Taux</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeVerif()">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body ">
                  <div class="mb-4" style="margin-bottom:3%;">
                    <div class="row justify-content-center" style="margin-bottom: 15px;">
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Devise de Départ. <span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <select v-model="this.form.devise_id" class="form-control border-input">
                                <option  v-for="(devise, index) in devises" :key="index" :value="devise.id">{{devise.devise_title}}</option>
                              </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-12">
                            <span>Devise de Conversion <span class="text-danger">*</span></span>
                          </div>
                          <div class="col-md-12">
                              <select v-model="this.form.devise_convert_id" class="form-control border-input">
                                <option  v-for="(devise, index) in devises" :key="index" :value="devise.id">{{devise.devise_title}}</option>
                              </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                          <input type="number" class="form-control border-input" placeholder="Search souscripteur"  v-model="this.form.taux_price" >
                        </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 text-right">
                      <button class="btn btn-default"  @click.prevent="close()"  type="button" data-dismiss="modal" aria-label="Close" style="margin-right: 4%;">Annuler </button>
                      <button class="btn btn-default" @click.prevent="submitTaux()"    type="button" data-dismiss="modal" aria-label="Close">Mettre à jour</button>
                    </div>
                  </div>
              </div>
              <!-- <div class="modal-footer ">
                  <button type="button " class="btn btn-secondary btn-block " data-dismiss="modal ">Ajouter</button>
              </div> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarGlobale from './layouts/NavBar.vue';
import NavbarGlobaleMobile from './layouts/NavBarMobile.vue';
import FooterGlobale from './layouts/FooterGlobale.vue';

import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'PaymentPage',
  components:{NavbarGlobale,FooterGlobale,NavbarGlobaleMobile},
  data() {
    return {
      user: null,
      devises: [],
      profilersUsers: [],
      usersAdmin: [],
      dt_taux: [],
      dt_DataExcel:[],
      dt_rules:[],
      dt_notifications:[],
      projetActif:null,
      projetActifPaymentsPg:[],
      souscripteur_store_search:"",
      souscritPay_store_search:"",
      souscripteurs_collect:[],
      projetActifPayments:[],
      form:{
        devise_id:null,
        devise_convert_id:null,
        taux_price:1,
      },
      form_notification:{
        sms_partenaire_id:null,
        sms_partenaire_souscription:null,
        sms_partenaire_add_price:null,
        sms_partenaire_rappel:null,
      },
      form_admin:{
        names:null,
        profiler_etat_civil:"Celibataire",
        profiler_phone:null,
        profiler_email:null,
        profiler_sex:null,
        rule_id:null,
        profiler_type:null
      },
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming:null,
      step_store:false
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    this.gb_deleteCss();
    this.gb_loadReFreshUser();

    this.data_rules();
    this.taux();
    this.all_devises();
    this.users_admin();
    this.notifications_sms();
    var datasExcel = JSON.parse(localStorage.getItem("DATA_EXCEL"));
    if (datasExcel.length > 0) {
      // this.dt_DataExcel = datasExcel;
      this.dt_DataExcel = datasExcel.filter(function(val){
        return val.names != null && val.names !=""
        // return val.names != null && val.names !="" && val.price != null && val.price !="" && val.price > 0
      });
    }

  },
  methods: {

    loadExcelSouscripteurs() {
      this.lottieloading = true;

      setTimeout(() => {
        this.data_excel_store();
        // this.data_excel_store_v1();
  
        var bax = setInterval(() => {
          if (this.dt_DataExcel.length == this.$store.state.user_dataExcel ) {
            this.lottieloading = false;
            clearInterval(bax);
          }
        }, 12000);
      }, 2000);
    },

    async notifications_sms() {
      await axios.get(this.$store.state.app_url_dev + "sms_partenaires").then(({ data }) => {
       if (data.status == 1) {
          this.dt_notifications = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async taux() {
      await axios.get(this.$store.state.app_url_dev + "taux").then(({ data }) => {
       if (data.status == 1) {
          this.dt_taux = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async users_admin() {
      await axios.get(this.$store.state.app_url_dev + "users_admin").then(({ data }) => {
       if (data.status == 1) {
          this.usersAdmin = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async all_devises() {
      await axios.get(this.$store.state.app_url_dev + "devises").then(({ data }) => {
        if (data.status == 1) {
          this.devises = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
        console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async submitTaux() {
      if (this.form.devise_id != null && this.form.devise_id != ""
        && this.form.devise_convert_id != null && this.form.devise_convert_id != ""
       && this.form.taux_price != null && this.form.taux_price != "") {

           await axios.post(this.$store.state.app_url_dev + "st_taux", this.form).then(({ data }) => {
          if (data.status == 1) {
            this.toast.info("Le taux a été mise à jour !!!", {
              timeout: 2000
            });

            setTimeout(() => {
              this.refreshStore();
              this.taux();
            }, 3000);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });

            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
              timeout: 4000
            });
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });

        
      } else {
          this.toast.info("Veuillez remplir les champs manquants !!!", {
          timeout: 2000
        });
      }
    },

    async submitAddAdmin() {
      if (this.form_admin.names != null && this.form_admin.names.trim() != ""
        && this.form_admin.profiler_phone != null && this.form_admin.profiler_phone.trim() != ""
       && this.form_admin.profiler_sex != null && this.form_admin.profiler_sex.trim() != "") {

        await axios.post(this.$store.state.app_url_dev + "user_admin", this.form_admin).then(({ data }) => {
          if (data.status == 1) {
            this.toast.info("L'administrateur vient d'etre ajouté'!!!", {
              timeout: 2000
            });

            var message = "Bonjour très chèr(e) " + this.form_admin.names + ".\n Votre compte administrateur vient d'etre crée pour le projet ouvriers partenaires.\n Vos accès sont les suivants : -Téléphone: "+ this.form_admin.profiler_phone.toString().trim() +".\n -mot de passe confidentiel :"+data.pass+" .\n Que Dieu vous benisse.\n cliquer ici: https://phila-invest.ce.church \n.  Contacts :+243828501407,heritage-influence@ce.church";

            let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form_admin.profiler_phone.toString().trim());
            axios.get(urlgetsms).then(() => {});

            setTimeout(() => {
              this.refreshStore();
              this.users_admin();
            }, 3000);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });

            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
              timeout: 4000
            });
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });

        
      } else {
          this.toast.info("Veuillez remplir les champs manquants !!!", {
          timeout: 2000
        });
      }
    },

    async submitUpdateNotificatinPartenaire() {
      if (this.form_notification.sms_partenaire_souscription != null && this.form_notification.sms_partenaire_souscription.trim() != ""
        && this.form_notification.sms_partenaire_add_price != null && this.form_notification.sms_partenaire_add_price.trim() != ""
       && this.form_notification.sms_partenaire_rappel != null && this.form_notification.sms_partenaire_rappel.trim() != "") {

        await axios.put(this.$store.state.app_url_dev + "sms_partenaire", this.form_notification).then(({ data }) => {
          if (data.status == 1) {
            this.toast.success("Les notifications viennent d'être mises à jour !!!", {
              timeout: 2000
            });

            setTimeout(() => {
              this.refreshStore();
              this.notifications_sms();
            }, 3000);
          } else {
            this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
              timeout: 4000
            });
          }
        }).catch(function (error) {
          console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
        
      } else {
          this.toast.info("Veuillez remplir au moins un champ !!!", {
          timeout: 2000
        });
      }
    },

    async data_rules() {
      await axios.get(this.$store.state.app_url_dev + "roles").then(({ data }) => {
       if (data.status == 1) {
          this.dt_rules = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async profilers_userS() {
      await axios.get(this.$store.state.app_url_dev + "users_profilers").then(({ data }) => {
       if (data.status == 1) {
          this.profilersUsers = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async close() {
      this.step_store=false;
      this.souscripteur_store_search = "";
    },

    async closeVerif() {
      if (this.souscripteurs_collect.length == 0) {
        this.close();
      }
    },

    annuler_store(){
      this.step_store = !this.step_store;
    },

    refreshStore() {
      this.form.devise_id = null;
      this.form.devise_convert_id = null;
      this.form.taux_price = null;
      this.form_admin.names = null;
      this.form_admin.profiler_phone = null;
      this.form_admin.profiler_email = null;
      this.form_admin.profiler_sex = null;
      this.form_notification.sms_partenaire_id = null;
      this.form_notification.sms_partenaire_souscription = null;
        this.form_notification.sms_partenaire_add_price =null;
        this.form_notification.sms_partenaire_rappel = null;
    },

    access_step_two() {
      if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
        this.lottieloading = true;
        // if (this.Online()) {
          setTimeout(() => {
            this.step = 2;
            this.lottieloading = false
          }, 3000);
        // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // }
      } else {
        this.checkForm = true;
        if (this.form.identity_middlename == null || this.form.identity_middlename.trim() == "") {
            this.toast.error("Veuillez compléter le nom", {
              timeout: 5000
            });
        } else {
          this.toast.error("Veuillez compléter les champs manquants", {
            timeout: 5000
          });
        }
      }
    },

    load_data_notification() {
      if (this.dt_notifications.length > 0) {
        this.form_notification.sms_partenaire_id = this.dt_notifications[0].id;
        this.form_notification.sms_partenaire_souscription = this.dt_notifications[0].sms_partenaire_souscription;
        this.form_notification.sms_partenaire_add_price = this.dt_notifications[0].sms_partenaire_add_price;
        this.form_notification.sms_partenaire_rappel = this.dt_notifications[0].sms_partenaire_rappel;
      }
    },

    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.identity_sex = typesexe;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return  value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  },

  computed:{
    search_souscritPay_souscripteurs() {
      return this.souscripteurs_collect.filter((element)=>{
        return element.names.trim().toLowerCase().includes(this.souscritPay_store_search.trim().toLowerCase()) || 
        element.phone.trim().toLowerCase().includes(this.souscritPay_store_search.trim().toLowerCase());
      });
    },

    load_rules_not_admin() {
      return this.dt_rules.filter((element)=>{
        return element.rule_designation.trim().toUpperCase() != "SOUSCRIPTEUR";
      });
    },
  }
}
</script>
