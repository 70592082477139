<template>
  <div class="sidebar" data-background-color="white" data-active-color="danger">
    <div class="sidebar-wrapper">
          <div class="logo">
              <a href="#" class="simple-text">
                  Administration Projet
              </a>
          </div>

          <ul class="nav">
              <li  :class="($route.path == '/dashboard' ? 'active' : '')">
                <router-link to="/dashboard">
                  <i class="ti-panel"></i>
                  <p>Dashboard</p>
                </router-link>
              </li>
              <li  :class="($route.path == '/payment' ? 'active' : '')">
                <router-link to="/payment">
                  <i class="ti-money"></i>
                  <p>Paiement</p>
                </router-link>
              </li>

              <li  :class="($route.path == '/rapport' ? 'active' : '')">
                <router-link to="/rapport">
                  <i class="ti-file"></i>
                  <p>Rapport</p>
                </router-link>
              </li>

              <li  :class="($route.path == '/settings' ? 'active' : '')">
                <router-link to="/settings">
                  <i class="ti-settings"></i>
                  <p>Paramètre</p>
                </router-link>
              </li>
              <!-- <li>
                  <a href="user.html">
                      <i class="ti-user"></i>
                      <p>User Profile</p>
                  </a>
              </li> -->
              <!-- <li class="d-none">
                  <a href="typography.html">
                      <i class="ti-text"></i>
                      <p>Typography</p>
                  </a>
              </li> -->
              <!-- <li>
                  <a href="icons.html">
                      <i class="ti-pencil-alt2"></i>
                      <p>Icons</p>
                  </a>
              </li> -->
              <!-- <li>
                  <a href="maps.html">
                      <i class="ti-map"></i>
                      <p>Maps</p>
                  </a>
              </li> -->
              <!-- <li>
                  <a href="notifications.html">
                      <i class="ti-bell"></i>
                      <p>Notifications</p>
                  </a>
              </li> -->
              <!-- <li class="active-pro">
                  <a href="upgrade.html">
                      <i class="ti-export"></i>
                      <p>Upgrade to PRO</p>
                  </a>
              </li> -->
          </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  name: 'LoadPage',

  data() {
    return {
      user: null,
      devises: [],
      form:{
        names:null,
        profiler_etat_civil:"Non défini",
        profiler_phone:null,
        profiler_email:null,
        profiler_sex:null,
        devise_id:null,
        projet_souscripteur_price_estimation:null,
        city_name:null,
        type_souscription_id:null,
        self_souscription:null,
        step_one:true,
        step_two:false
      },
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming:null
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  mounted() {
    // this.all_devises();
    // // this.all_cities();
    // // this.all_levels();
    
    // setInterval(() => {
    //   this.all_devises();
    //   // this.all_cities();
    //   // this.all_levels();
    // }, 60000);
  },
  methods: {

    async  all_devises() {
      await axios.get(this.$store.state.app_url_dev + "devises").then(({ data }) => {
       if (data.status == 1) {
          this.devises = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },


    async storeSouscripteur() {
      
      if(this.form.self_souscription !=null ){
        this.form.type_souscription_id = 2;
      }else{
        this.form.type_souscription_id = 1;
      }

      if(this.form.names.trim() !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone.trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      //  var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());

        await axios.post(this.$store.state.app_url_dev + "souscripteur",this.form).then(({ data }) => {
        if (data.status == 1) {
          var message = "Bonjour " + (this.form.profiler_sex.toString().trim() == "M" ? "Mr " : "Mme ") + (this.form.names.toString().trim().toUpperCase()) + ".\n Votre souscription vient d'être fait pour le projet 7000 Partenaires. (Phila-c.e)";

          let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=Phila&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());
          // let urlgetsms = "https://sms.eliajimmy.net/apisms.php?user=pedrienk@gmail.com&password=Phila@2023&message=" + message + "&expediteur=test&telephone=" + this.getNumberFormatSms(this.form.profiler_phone.toString().trim());

          axios.get(urlgetsms).then(() => { });
          // this.$store.state.user_auth = data;
          // this.$store.state.user_store = data;
          // setTimeout(() => {
          //     this.refreshForm();
          //     this.step = 1;
          //     this.lottieloading = false;
          //   }, 15001);

          setTimeout(() => {
            this.form.step_one = false;
            this.form.step_two = true;
            this.lottieloading = false;
            this.reseRefreshForm();

            this.settiming = setInterval(() => {
              this.newsSouscripteur();
            }, 6000);
            
          }, 4500);
          } else {
            // this.toast.error(data.message, {
            //   timeout: 6000
            // });
          }
        }).catch(function (error) {
        console.log(error);
          this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
            timeout: 2000
          });
        });
      }else{
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    to_submit_form() {
      // if (this.form.names.toString().trim() !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex.toString().trim() !=null) {
      if(this.form.names !=null && this.form.names.toString().trim() !=null && this.form.profiler_phone !=null && this.form.profiler_phone.toString().trim() !=null && this.form.projet_souscripteur_price_estimation !=null && this.form.projet_souscripteur_price_estimation.toString().trim() !=null && this.form.profiler_sex !=null && this.form.profiler_sex.toString().trim() !=null) {
        this.lottieloading = true;
        // if (this.Online()) {
          this.storeSouscripteur();
          // this.RegisterDemandUser();
           // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // } 
      } else {
        this.checkForm = true;
        this.toast.error("Veuillez compléter les champs manquants", {
          timeout: 5000
        });
      }
    },

    reseRefreshForm() {
      this.form.names = null;
      this.form.profiler_etat_civil = "Non défini";
      this.form.profiler_phone = null;
      this.form.profiler_email = null;
      this.form.profiler_sex = null;
      this.form.devise_id = null;
      this.form.projet_souscripteur_price_estimation = null;
      this.form.city_name = null;
      this.form.type_souscription_id = null;
      this.form.self_souscription = null;
    },

    newsSouscripteur() {
      clearInterval(this.settiming);
      this.reseRefreshForm();
      this.form.step_one = true;
      this.form.step_two = false;
      this.lottieloading = false;
      this.settiming = null;
    },





    async  all_modules() {
      await axios.get(this.$store.state.app_url_dev + "modules").then(({ data }) => {
       if (data.status == 1) {
          this.modules = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_levels() {
      await axios.get(this.$store.state.app_url_dev + "levels").then(({ data }) => {
       if (data.status == 1) {
          this.levels = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_cities() {
      await axios.get(this.$store.state.app_url_dev + "cities").then(({ data }) => {
       if (data.status == 1) {
          this.cities = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
      }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async  all_subjectRdvs() {
      await axios.get(this.$store.state.app_url_dev + "subjects").then(({ data }) => {
       if (data.status == 1) {
          this.subjectRdvs = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async all_orgas() {
      await axios.get(this.$store.state.app_url_dev + "orgas").then(({ data }) => {
       if (data.status == 1) {
          this.orgas = data.data;
        } else {
          this.toast.error(data.message, {
            timeout: 6000
          });
        }
     }).catch(function (error) {
       console.log(error);
        this.toast.info("Une érreur est survenue, veuillez réessayer  !!!", {
          timeout: 2000
        });
      });
    },

    async search_all_orgas() {
      this.all_orgas();
      if(this.formF.title.toString() !=null && this.formF.title.toString().trim() !="" && this.formF.title.trim().length >= 2 && this.orgas.length > 0) {
        this.searchOrgas = this.orgas.filter((orga)=>{
          return orga.title.trim().toLowerCase().includes(this.formF.title.trim().toLowerCase());
        });
      }else{
        this.searchOrgas =[];
      }
    },

    select_orga(id) {
      if(id.toString() !=null && id.toString().trim() !="" &&this.orgas.length > 0) {
       var orga = this.orgas.find((org)=>org.id.toString().trim() == id.toString().trim());
       if(orga !=null){
        this.formF.title = orga.title;
        this.formF.person_name = orga.person_name;
        this.formF.phone_one = orga.phone_one;
        this.formF.email = orga.email;
        this.formF.adress = orga.adress;
        this.searchOrgas =[];
       }
      }
    },

    storeLevelMod(mod_id) {
      
      if(this.formF.index_participant !=0 ){
        this.formF.index_participant++;
      }

      if(mod_id.toString() !=null && mod_id.toString().trim() !="" &&this.modules.length > 0 && this.formF.level_store_particicpant !=null) {
       var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());
       if(mod !=null){

        if(this.formF.array_participants.length > 1){
          this.formF.array_participants[this.formF.index_participant].profilers.push({
            "module_id":mod.id,
            "level_id":this.formF.level_store_particicpant
         });
         console.log( this.formF.array_participants);
        }else{

          this.formF.array_participants.push({
          "profilers":[{
              "module_id":mod.id,
              "level_id":this.formF.level_store_particicpant
            }]
         });
         console.log( this.formF.array_participants);
        }
        this.formF.level_store_particicpant =null;
       }
      }
    },

    selectMod() {
      if(this.formF.module_store_particicpant !=null && this.formF.module_store_particicpant.toString().trim() !="" && this.modules.length > 0) {
       var Modules = this.modules.filter((mod)=>{
        return mod.id.toString().trim() == this.formF.module_store_particicpant.toString().trim() || mod.module_title.toString().toUpperCase() =='INFORMATIQUE';
       });
       this.modParticipants = Modules;
      //  this.formF.array_participants.({
      //   "modules":[]});
       this.formF.array_participants.push({
        "modules":[{
                "id": this.formF.module_store_particicpant
              }]
       });
      }else{
        this.modParticipants = [];
      }
    },

    
    access_step_two() {
      if (this.form.identity_firstname != null && this.form.identity_firstname.trim() != "" && this.form.identity_middlename != null && this.form.identity_middlename.trim() != "" && this.form.identity_sex != null && this.form.identity_sex.trim() != "" && this.form.tranche_age_id != null && this.form.tranche_age_id.toString().trim() != "") {
        this.lottieloading = true;
        // if (this.Online()) {
          setTimeout(() => {
            this.step = 2;
            this.lottieloading = false
          }, 3000);
        // } else {
        //     setTimeout(() => {
        //     this.lottieloading = false
        //   }, 2500);
        // } 
      } else {
        this.checkForm = true;
        if (this.form.identity_middlename == null || this.form.identity_middlename.trim() == "") {
            this.toast.error("Veuillez compléter le nom", {
              timeout: 5000
            });
        } else {
          this.toast.error("Veuillez compléter les champs manquants", {
            timeout: 5000
          });
        }
      }
    },


    changesexe(typesexe) {
      if (this.isSexe) {
        this.isSexe = false;
      } else {
        this.isSexe = true;
      }
      this.form.identity_sex = typesexe;
    },

    IsEmail(email) {
      var regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      if (!regex.test(email)) {
        this.toast.error("Adresse email invalide", {
          timeout: 5000
        });
        return false;
      } else {
        return true;
      }
    },

    // verifi numero e telephone
    getValidNumber(value) {
      if (value.length == 10 || value.length == 12 || value.length == 13) {

        if (value.length == 10) {
          if (value.substring(0, 1) == '0') {
            if (value.substring(1, 3) == "81" || value.substring(1, 3) == "82" || value.substring(1, 3) == "83" || value.substring(1, 3) == "84" || value.substring(1, 3) == "85" ||
              value.substring(1, 3) == "89" || value.substring(1, 3) == "90" || value.substring(1, 3) == "91" || value.substring(1, 3) == "97" || value.substring(1, 3) == "98" ||
              value.substring(1, 3) == "80" || value.substring(1, 3) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }

        } else if (value.length == 12) {

          if (value.substring(0, 3) == '243') {

            if (value.substring(3, 5) == "81" || value.substring(3, 5) == "82" || value.substring(3, 5) == "83" || value.substring(3, 5) == "84" || value.substring(3, 5) == "85" ||
              value.substring(3, 5) == "89" || value.substring(3, 5) == "90" || value.substring(3, 5) == "91" || value.substring(3, 5) == "97" || value.substring(3, 5) == "98" ||
              value.substring(3, 5) == "80" || value.substring(3, 5) == "99") {
              return true;
            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        } else if (value.length == 13) {

          if (value.substring(0, 1) == '+') {

            if (value.substring(1, 4) == '243') {
              if (value.substring(4, 6) == "81" || value.substring(4, 6) == "82" || value.substring(4, 6) == "83" || value.substring(4, 6) == "84" || value.substring(4, 6) == "85" ||
                value.substring(4, 6) == "89" || value.substring(4, 6) == "90" || value.substring(4, 6) == "97" || value.substring(4, 6) == "98" ||
                value.substring(4, 6) == "80" || value.substring(4, 6) == "99") {
                return true;
              } else {
                this.toast.error("Numéro de téléphone invalide", {
                  timeout: 5000
                });
                return false;
              }

            } else {
              this.toast.error("Numéro de téléphone invalide", {
                timeout: 5000
              });
              return false;
            }

          } else {
            this.toast.error("Numéro de téléphone invalide", {
              timeout: 5000
            });
            return false;
          }
        }

      } else {
        this.toast.error("Numéro de téléphone invalide", {
          timeout: 5000
        });
        return false;
      }
      return false;
    },

    getNumberFormat(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value;
        } else if (value.length == 12) {
          return '+' + value;
        } else if (value.length == 10) {
          return '+243' + value.substring(1, value.length);
        }
      }
    },

    getNumberFormatSms(value) {
      if (value.length > 0) {
        if (value.length == 13 && value.substring(0, 1) == '+') {
          return value.substring(1, value.length);
        } else if (value.length == 12) {
          return  value;
        } else if (value.length == 10) {
          return '243' + value.substring(1, value.length);
        }
      }
    },
  }
}
</script>
