<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar bar1"></span>
                <span class="icon-bar bar2"></span>
                <span class="icon-bar bar3"></span>
            </button>
            <a class="navbar-brand" href="#">Dashboard</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <!-- <li>
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="ti-panel"></i>
        <p>Stats</p>
                    </a>
                </li> -->
                <!-- <li class="dropdown">
                      <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="ti-bell"></i>
                            <p class="notification">5</p>
                            <p style="margin:0 5px !important;"> {{ this.$store.state.user_auth?.profiler?.profiler_prenom }}</p>
                      <b class="caret"></b>
                      </a>
                      <ul class="dropdown-menu">
                        <li><a href="#">Notification 1</a></li>
                        <li><a href="#">Notification 2</a></li>
                        <li><a href="#">Notification 3</a></li>
                        <li><a href="#">Notification 4</a></li>
                        <li><a href="#">Another notification</a></li>
                      </ul>
                </li> -->
                <li class="dropdown">
                      <a href="#"  @click.prevent="deconnexion" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="ti-settings"></i>
                            <!-- <p class="notification">5</p> -->
                            <p style="margin:0 5px !important;"> {{ this.$store.state.user_auth?.profiler?.profiler_prenom }}</p>
                      <b class="caret"></b>
                      </a>
                      <ul class="dropdown-menu">
                        <!-- <li><a href="#">Notification 1</a></li>
                        <li><a href="#">Notification 2</a></li>
                        <li><a href="#">Notification 3</a></li>
                        <li><a href="#">Notification 4</a></li> -->
                        <li><a href="#">Deconexion</a></li>
                      </ul>
                </li>
                
                 <!-- <li>
                    <a href="#">
                      <i class="ti-settings"></i>
                      <p style="margin:0 5px !important;"> {{ this.$store.state.user_auth?.profiler?.profiler_prenom }}</p>
                    </a>
                </li> -->
            </ul>

        </div>
    </div>
  </nav>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: 'NavBarMobileBlock',

  data() {
    return {
      user: null,
      devises: [],
      form:{
        names:null,
        profiler_etat_civil:"Non défini",
        profiler_phone:null,
        profiler_email:null,
        profiler_sex:null,
        devise_id:null,
        projet_souscripteur_price_estimation:null,
        city_name:null,
        type_souscription_id:null,
        self_souscription:null,
        step_one:true,
        step_two:false
      },
      requesting: false,
      step: 1,
      checkForm: false,
      lottieloading: false,
      settiming:null
    };
  },
  setup() {
    const toast = useToast();
    return { toast }
  },
  methods: {
    storeLevelMod(mod_id) {
      
      if(this.formF.index_participant !=0 ){
        this.formF.index_participant++;
      }

      if(mod_id.toString() !=null && mod_id.toString().trim() !="" &&this.modules.length > 0 && this.formF.level_store_particicpant !=null) {
       var mod = this.modules.find((mod)=>mod.id.toString().trim() == mod_id.toString().trim());
       if(mod !=null){

        if(this.formF.array_participants.length > 1){
          this.formF.array_participants[this.formF.index_participant].profilers.push({
            "module_id":mod.id,
            "level_id":this.formF.level_store_particicpant
         });
         console.log( this.formF.array_participants);
        }else{

          this.formF.array_participants.push({
          "profilers":[{
              "module_id":mod.id,
              "level_id":this.formF.level_store_particicpant
            }]
         });
         console.log( this.formF.array_participants);
        }
        this.formF.level_store_particicpant =null;
       }
      }
    },

    deconnexion() {
      this.$store.commit('AUTHENTIFICATE_USER', null);
      localStorage.setItem('AUTHENTIFICATE_USER', null);
      this.$store.state.user_auth =null;
      this.$router.push({ path: '/' })
    }
  }
}
</script>
